import { useNotificationHandlerContext } from "contexts/Resource/NotificationHandlerContext";
import { getItemsNotUndefined, onReadyResponse } from "utils/Helper";
import React, { createContext, useContext, useState } from "react";
import ApiService from "services/ApiService";
import { isEmpty, set } from "lodash";
import { useAuth } from "./AuthContext";

const PopupContext = createContext({});

export function PopupProvider({ children }) {
  const { notify } = useNotificationHandlerContext();
  const [loading, onLoading] = useState(false);
  const [reloadGrid, onReloadGrid] = useState(false);
  const [routesApplication, setRoutesApplication] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [recordsSelecteds, setRecordsSelecteds] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [popup, setPopup] = useState();
  const [popupImage, setPopupImage] = useState();
  const [popups, setPopups] = useState([]);
  const [popupsCloseds, setPopupsCloseds] = useState([]);

  const { user } = useAuth();

  const getPopups = () => {
    onLoading(true);
    ApiService.get(`popups-user`, {
      active: 1,
      userId: user?.id,
      pageSize: 9999,
    }).then((response) => {
      setPopups(response?.data);
      onLoading(false);
    });
  };

  const onCreatePopup = (values, form) => {
    const PopupData = getItemsNotUndefined({ ...values, image: popupImage });

    if (isEmpty(popupImage)) {
      notify(
        "error",
        "create-popup",
        "Por favor, adicione uma imagem para o popup.",
        null,
        "notification"
      );
      return;
    }

    onLoading(true);
    ApiService.post(`popups`, PopupData).then((response) => {
      onReadyResponse(
        response,
        notify,
        "create-popup",
        "Popup cadastrado com sucesso!",
        onLoading,
        onReloadGrid,
        reloadGrid,
        form
      );
    });
  };

  const onUpdatePopup = (values) => {
    const PopupData = getItemsNotUndefined({ ...values, image: popupImage });

    if (isEmpty(popupImage)) {
      notify(
        "error",
        "create-popup",
        "Por favor, adicione uma imagem para o popup.",
        null,
        "notification"
      );
      return;
    }

    onLoading(true);
    ApiService.put(`popups/${popup?.id}`, PopupData).then((response) => {
      onReadyResponse(
        response,
        notify,
        "edit-popup",
        "Popup atualizado com sucesso!",
        onLoading
      );
    });
  };

  const onFillPopupForm = (form, PopupData) => {
    form.setFieldsValue({ name: PopupData?.name });
    form.setFieldsValue({ popup: PopupData?.popup });

    if (PopupData?.companies) {
      form.setFieldsValue({
        companies: PopupData?.companies.map((company) => company.id),
      });
    }

    if (PopupData?.roles) {
      form.setFieldsValue({
        roles: PopupData?.roles.map((role) => role.id),
      });
    }
  };

  const onChangeStatus = (data, value) => {
    onLoading(true);
    const popupData = { active: value === true ? 1 : 0 };
    ApiService.patch(`popups/${data.id}`, popupData)
      .then((response) => {
        onReadyResponse(
          response,
          notify,
          "edit-popup",
          "Status atualizado com sucesso!",
          onLoading,
          onReloadGrid,
          reloadGrid,
          null,
          "notification"
        );
      })
      .catch(() => {
        onLoading(false);
      });
  };

  const onClosePopup = (popup_id) => {
    onLoading(true);
    const popupData = { user_id: user?.id };
    ApiService.post(`close-modal/${popup_id}`, popupData)
      .then((response) => {
        onLoading(false);
        setPopupsCloseds([...popupsCloseds, popup_id]);
      })
      .catch(() => {
        onLoading(false);
      });
  };

  const onDeletePopup = async (id) => {
    await ApiService.delete(`popups/${id}`).then((response) => {
      onReadyResponse(
        response,
        notify,
        "delete-popup",
        "Popup deletado com sucesso!",
        onLoading,
        onReloadGrid,
        reloadGrid,
        null,
        "notification"
      );
    });
  };

  const onCallbackSelectRow = (selectedRows) => {
    setRecordsSelecteds(selectedRows);
  };

  const onDeletePopupsSelecteds = async () => {
    if (!isEmpty(recordsSelecteds)) {
      onLoading(true);
      Promise.all(
        recordsSelecteds.map(async (record) => {
          await ApiService.delete(`popups/${record?.id}`);
        })
      ).then(() => {
        onLoading(false);
        onReloadGrid(!reloadGrid);
        setRecordsSelecteds([]);
        notify(
          "success",
          "delete-user",
          "Registros deletados com sucesso.",
          null,
          "notification"
        );
      });
    } else {
      notify(
        "error",
        "delete-user",
        "Não há registros selecionados para deletar.",
        null,
        "notification"
      );
    }
  };

  return (
    <PopupContext.Provider
      value={{
        onCreatePopup,
        onUpdatePopup,
        onLoading,
        loading,
        popup,
        setPopup,
        onFillPopupForm,
        onChangeStatus,
        reloadGrid,
        onReloadGrid,
        permissions,
        routesApplication,
        setRoutesApplication,
        setPermissions,
        onDeletePopup,
        onDeletePopupsSelecteds,
        onCallbackSelectRow,
        setCompanyId,
        companyId,
        setPopupImage,
        popupImage,
        getPopups,
        popups,
        onClosePopup,
        popupsCloseds,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
}

export function usePopupContext() {
  const context = useContext(PopupContext);
  return context;
}

export default PopupContext;
