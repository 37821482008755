import React, { createContext, useContext, useEffect, useState } from "react";
import ApiService from "services/ApiService";
import { useNotificationHandlerContext } from "./Resource/NotificationHandlerContext";
import { message } from "antd";
import { isEmpty } from "utils/Helper";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { notify } = useNotificationHandlerContext();
  const [user, setUser] = useState(null);
  const [navigation, setNavigation] = useState(null);
  const [conexaEmbed, setConexaEmbed] = useState(null);
  const [photoProfile, setPhotoProfile] = useState(null);
  const [themeConfig, setThemeConfig] = useState(null);
  const [loading, onLoading] = useState(false);

  const Login = async ({ email, password }) => {
    onLoading(true);
    return await ApiService.post("login", {
      email: email,
      password: password,
    })
      .then((resp) => {
        if (resp?.access_token !== undefined) {
          localStorage.setItem("@App:token", resp.access_token);
          localStorage.setItem("@App:user", JSON.stringify(resp.user));
          localStorage.setItem(
            "@App:theme_config",
            JSON.stringify(resp.theme_config)
          );
          localStorage.setItem(
            "@App:navigation",
            JSON.stringify(resp.navigation)
          );
          localStorage.setItem("@App:conexa_embed", resp.conexa_embed_url);

          setConexaEmbed(resp.conexa_embed_url);
          setNavigation(resp.navigation);
          setUser(resp.user);
          setThemeConfig(resp.theme_config);
          setPhotoProfile(resp.user.profile);
        } else {
          notify("error", "authentication", resp.message);
          onLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 401) {
          notify("error", "authentication", "Sistema indisponível no momento.");
          onLoading(false);
        } else {
          notify("error", "authentication", "Sistema indisponível no momento.");
          onLoading(false);
        }
      });
  };

  const Logout = () => {
    onLoading(false);
    setUser(null);
    setThemeConfig(null);
    localStorage.removeItem("@App:user");
    localStorage.removeItem("@App:access");
    localStorage.removeItem("@App:token");
    localStorage.removeItem("@App:navigation");
    localStorage.removeItem("@App:language");
    localStorage.removeItem("@App:conexa_embed");
    localStorage.removeItem("@App:theme_config");
  };

  /** Recuperando o usuário e token da sessão */
  useEffect(() => {
    const storagedUser = localStorage.getItem("@App:user");
    const storageThemeConfig = localStorage.getItem("@App:theme_config");
    const storagedToken = localStorage.getItem("@App:token");
    const storagedNavigation = localStorage.getItem("@App:navigation");
    const storagedConexaEmbed = localStorage.getItem("@App:conexa_embed");

    if (storageThemeConfig) {
      setThemeConfig(JSON.parse(storageThemeConfig));
    }

    if (storagedToken && storagedUser) {
      const userData = JSON.parse(storagedUser);

      setUser(userData);
      setNavigation(JSON.parse(storagedNavigation));
      setConexaEmbed(storagedConexaEmbed);
      setPhotoProfile(userData?.profile);
    }
  }, []);

  const getToken = () => {
    return localStorage.getItem("@App:token");
  };

  const ConfirmCode = async ({ email, code }) => {
    return ApiService.post("confirm-code", {
      email: email,
      code: code,
    });
  };

  const ChangePassword = async ({ email, password }) => {
    return ApiService.post("change-password", {
      email: email,
      password: password,
    });
  };

  const Forgot = async ({ email }) => {
    return ApiService.post("forgot-password", {
      email: email,
    });
  };

  const canViewFinancialData = () => {
    if (user?.role?.view_financial_data === 1) {
      return true;
    }

    return false;
  };

  const isOnlyView = () => {
    let view = false;
    navigation.map((nav) => {
      if (nav.submenu.length > 0) {
        return nav.submenu.map((sub) => {
          if (`/${sub.path}` === window.location.pathname) {
            if (sub.only_view === 1) {
              view = true;
            }
          }
        });
      } else {
        if (`/${nav.path}` === window.location.pathname) {
          if (nav.only_view === 1) {
            view = true;
          }
        }
      }
    });

    return view;
  };

  const isMaster = () => {
    if (user?.role?.id === 1) {
      return true;
    }

    return false;
  };

  const isPopupHumorActive = () => {
    return user?.isPopUpHumorActive === 1;
  };

  const getPhoto = () => {
    if (photoProfile) {
      return photoProfile;
    }

    return "/img/avatars/thumb-1.jpg";
  };

  const isAdminCompany = () => {
    return user?.is_admin_company === 1;
  };

  const onUpdateConfiguration = async (json) => {
    onLoading(true);
    return await ApiService.post("companies/configurations", {
      json: json,
    })
      .then((resp) => {
        if (resp?.success === true) {
          message.success("Configurações atualizadas com sucesso.");
        } else {
          notify("error", "authentication", resp.message);
          onLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 401) {
          notify("error", "authentication", "Sistema indisponível no momento.");
          onLoading(false);
        } else {
          notify("error", "authentication", "Sistema indisponível no momento.");
          onLoading(false);
        }
      });
  };

  useEffect(() => {
    console.log("themeConfigAuth", themeConfig);
  }, [themeConfig]);

  return (
    <AuthContext.Provider
      value={{
        signed: Boolean(user),
        user,
        setUser,
        Login,
        Logout,
        getToken,
        loading,
        Forgot,
        ConfirmCode,
        ChangePassword,
        navigation,
        canViewFinancialData,
        isOnlyView,
        isMaster,
        conexaEmbed,
        isPopupHumorActive,
        getPhoto,
        setPhotoProfile,
        isAdminCompany,
        onUpdateConfiguration,
        themeConfig,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export default AuthContext;
