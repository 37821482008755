import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users`,
    component: React.lazy(() => import("views/app-views/users/index")),
  },
  {
    key: "roles",
    path: `${APP_PREFIX_PATH}/roles`,
    component: React.lazy(() => import("views/app-views/roles/index")),
  },
  {
    key: "companies",
    path: `${APP_PREFIX_PATH}/companies`,
    component: React.lazy(() => import("views/app-views/companies/index")),
  },
  {
    key: "health-and-wellness",
    path: `${APP_PREFIX_PATH}/health-and-wellness`,
    component: React.lazy(() =>
      import("views/app-views/health-and-wellness/index")
    ),
  },
  {
    key: "guidelines",
    path: `${APP_PREFIX_PATH}/guidelines`,
    component: React.lazy(() => import("views/app-views/guidelines/index")),
  },
  {
    key: "humor",
    path: `${APP_PREFIX_PATH}/humor`,
    component: React.lazy(() => import("views/app-views/humor/index")),
  },
  {
    key: "report-humor",
    path: `${APP_PREFIX_PATH}/report-humor`,
    component: React.lazy(() => import("views/app-views/reports/humor/index")),
  },
  {
    key: "banners",
    path: `${APP_PREFIX_PATH}/banners`,
    component: React.lazy(() => import("views/app-views/banners/index")),
  },
  {
    key: "home-content",
    path: `${APP_PREFIX_PATH}/admin/home-content`,
    component: React.lazy(() =>
      import("views/app-views/admin/home-content/index")
    ),
  },
  {
    key: "popups",
    path: `${APP_PREFIX_PATH}/admin/popup`,
    component: React.lazy(() => import("views/app-views/admin/popup/index")),
  },
  {
    key: "news",
    path: `${APP_PREFIX_PATH}/news`,
    component: React.lazy(() => import("views/app-views/news/index")),
  },
  {
    key: "admin-news",
    path: `${APP_PREFIX_PATH}/admin/news`,
    component: React.lazy(() => import("views/app-views/admin/news/index")),
  },
  {
    key: "events",
    path: `${APP_PREFIX_PATH}/events`,
    component: React.lazy(() => import("views/app-views/event/index")),
  },
  {
    key: "admin-products",
    path: `${APP_PREFIX_PATH}/admin/products`,
    component: React.lazy(() => import("views/app-views/admin/products/index")),
  },
  {
    key: "admin-clinics",
    path: `${APP_PREFIX_PATH}/admin/clinics`,
    component: React.lazy(() => import("views/app-views/admin/clinics/index")),
  },
  {
    key: "admin-gallery",
    path: `${APP_PREFIX_PATH}/admin/gallery`,
    component: React.lazy(() => import("views/app-views/admin/gallery/index")),
  },
  {
    key: "gallery",
    path: `${APP_PREFIX_PATH}/gallery`,
    component: React.lazy(() => import("views/app-views/gallery/index")),
  },
  {
    key: "admin-type-tickets",
    path: `${APP_PREFIX_PATH}/admin/type-tickets`,
    component: React.lazy(() =>
      import("views/app-views/admin/type-tickets/index")
    ),
  },
  {
    key: "admin-type-tickets",
    path: `${APP_PREFIX_PATH}/tickets`,
    component: React.lazy(() => import("views/app-views/tickets/index")),
  },
  {
    key: "feedbacks",
    path: `${APP_PREFIX_PATH}/feedbacks`,
    component: React.lazy(() => import("views/app-views/feedbacks/index")),
  },
  {
    key: "useful-phones",
    path: `${APP_PREFIX_PATH}/admin/useful-phones`,
    component: React.lazy(() =>
      import("views/app-views/admin/useful-phones/index")
    ),
  },
  {
    key: "contact",
    path: `${APP_PREFIX_PATH}/contact`,
    component: React.lazy(() => import("views/app-views/contact/index")),
  },
  {
    key: "admin-documents",
    path: `${APP_PREFIX_PATH}/admin/documents`,
    component: React.lazy(() =>
      import("views/app-views/admin/documents/index")
    ),
  },
  {
    key: "documents",
    path: `${APP_PREFIX_PATH}/documents`,
    component: React.lazy(() => import("views/app-views/documents/index")),
  },
  {
    key: "profile",
    path: `${APP_PREFIX_PATH}/profile`,
    component: React.lazy(() => import("views/app-views/profile/index")),
  },
  {
    key: "imports",
    path: `${APP_PREFIX_PATH}/admin/imports`,
    component: React.lazy(() => import("views/app-views/admin/imports/index")),
  },
  {
    key: "wellhub",
    path: `${APP_PREFIX_PATH}/wellhub`,
    component: React.lazy(() => import("views/app-views/wellhub/index")),
  },
  {
    key: "external-routes",
    path: `${APP_PREFIX_PATH}/admin/external-routes`,
    component: React.lazy(() => import("views/app-views/admin/external-routes/index")),
  },
  {
    key: "report-user",
    path: `${APP_PREFIX_PATH}/report-user`,
    component: React.lazy(() => import("views/app-views/reports/user/index")),
  },
];
