import { Modal, Spin } from "antd";
import { usePopupContext } from "contexts/PopupContext";
import { useEffect } from "react";

const PopupModal = () => {
  const { getPopups, popups, onClosePopup, popupsCloseds, loading } =
    usePopupContext();

  useEffect(() => {
    getPopups();
  }, []);

  return (
    popups?.length > 0 &&
    popups?.map((popup, index) => {
      return (
        <Modal
          visible={popupsCloseds?.indexOf(popup?.id) === -1}
          onCancel={() => onClosePopup(popup?.id)}
          footer={null}
          width="600px"
          key={index}
        >
          <Spin spinning={loading}>
            <img
              src={popup?.url_image}
              alt={popup?.title}
              style={{ width: "100%" }}
            />
          </Spin>
        </Modal>
      );
    })
  );
};

export default PopupModal;
