import React, { createContext, useContext, useEffect, useState } from "react";
import ApiService from "services/ApiService";
import { useAuth } from "./AuthContext";

const HumorHistoryContext = createContext({});

export function HumorHistoryProvider({ children }) {
  const [showModal, onShowModal] = useState(false);
  const { user } = useAuth();
  const [loading, onLoading] = useState(false);
  const [reloadGrid, onReloadGrid] = useState(false);
  const [routesApplication, setRoutesApplication] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [humorHistory, setHumorHistory] = useState({});
  const [humorHistories, setHumorHistories] = useState([]);
  const [period, setPeriod] = useState(null);
  const [type, setType] = useState(null);
  const [company, setCompany] = useState(null);
  const [params, setParams] = useState({});

  const onCreateHumorHistory = async (type) => {
    onLoading(true);
    return ApiService.post(`humor-histories`, {
      user_id: user?.id,
      company_id: user?.company_id,
      type,
    }).then(
      (response) => {
        setHumorHistory(response.humorHistory);
        onLoading(false);
      }
    );
  };

  const onUpdateHumorHistory = async (reason) => {
    onLoading(true);
    return ApiService.put(`humor-histories/${humorHistory?.id}`, {
      reason: reason
    }).then(
      (response) => {
        setHumorHistory(response.humorHistory);
        onLoading(false);
      }
    );
  };

  const onSearchHumorToday = async (values, form) => {
    onLoading(true);
    return ApiService.get(`humor-histories`, {
        user_id: user?.id,
        created_at: 'now'
    }).then(
      (response) => {
        setHumorHistory(response.data[0]);
        onLoading(false);

        if (user !== null && user?.isPopUpHumorActive === 1 && response.data.length === 0) {
          onShowModal(true);
        } else {
          onShowModal(false);
        }
      }
    );
  };

  const onSearchHumorByCompany = async (params = {}) => {
    onLoading(true);
    return ApiService.get(`humor/report`, params).then(
      (response) => {
        setHumorHistories(response.report);
        onLoading(false);
      }
    );
  };

  const onChangePeriod = (dates, dateStrings) => {
    setPeriod(`${dateStrings[0]},${dateStrings[1]}`);
  }

  const onChangeType = (type) => {
    setType(type);
  }

  const onChangeCompany = (company_id) => {
    setCompany(company_id);
  }

  const onClearFilters = () => {
    let clearedFilters = {};
    
    setType(null);
    setPeriod(null);

    if(params['company_id']){
      clearedFilters['company_id'] = params['company_id'];
      setParams(clearedFilters);
    }
  }

  const onFilterCallbackFunction = (column, value) => {

    let index = column;

    params[index] = value;
    console.log(params);
    return params;
  }

  useEffect(() => {

    if(company){
      onFilterCallbackFunction('company_id', company);
    }

    if(type){
      onFilterCallbackFunction('type', type);
    }

    if(period){
      onFilterCallbackFunction('created_at', period);
    }

    if(params['company_id']){
      onSearchHumorByCompany(params);
    }

  }, [type, period, company]);

  useEffect(() => {
    if (user?.id !== undefined) {
      onSearchHumorToday();
    }
  }, [user?.id]);

  return (
    <HumorHistoryContext.Provider
      value={{
        onCreateHumorHistory,
        onLoading,
        loading,
        humorHistory,
        setHumorHistory,
        reloadGrid,
        onReloadGrid,
        permissions,
        routesApplication,
        setRoutesApplication,
        setPermissions,
        showModal,
        onUpdateHumorHistory,
        onShowModal,
        onSearchHumorByCompany,
        onChangePeriod,
        onChangeType,
        onChangeCompany,
        onClearFilters,
        period,
        company,
        type,
        params,
        humorHistories
      }}
    >
      {children}
    </HumorHistoryContext.Provider>
  );
}

export function useHumorHistoryContext() {
  const context = useContext(HumorHistoryContext);
  return context;
}

export default HumorHistoryContext;
