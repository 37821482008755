import React, { lazy, Suspense, memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ConfigProvider, Modal, message } from "antd";
import Loading from "components/shared-components/Loading";
import { lightTheme, darkTheme } from "configs/ThemeConfig";
import { resources } from "lang";
import useBodyClass from "utils/hooks/useBodyClass";
import Routes from "routes";
import { useAuth } from "contexts/AuthContext";
import PasswordChecker from "views/auth-views/authentication/forgot-password/PasswordChecker";
import Humor from "components/app-components/Humor";
import PopupModal from "components/app-components/Popup/Modal";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const Layouts = () => {
  const { user, ChangePassword, setUser, signed } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isChangePassword, setisChangePassword] = useState(false);
  const [isOpenPopupHumor, setIsOpenPopupHumor] = useState(false);
  const blankLayout = useSelector((state) => state.theme.blankLayout);

  const Layout = signed && !blankLayout ? AppLayout : AuthLayout;

  const locale = useSelector((state) => state.theme.locale);

  const direction = useSelector((state) => state.theme.direction);

  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const currentAppLocale = resources[locale];

  useBodyClass(`dir-${direction}`);

  const themeConfig =
    currentTheme === "light" ? { ...lightTheme } : { ...darkTheme };

  useEffect(() => {
    if (user !== null && user?.change_passoword === 1) {
      setisChangePassword(true);
    }

    if (user !== null && user?.isPopUpHumorActive === 1) {
      setIsOpenPopupHumor(true);
    }
  }, [user]);

  const handleChangePassword = (password, passwordIsValid) => {
    setLoading(true);

    if (passwordIsValid) {
      ChangePassword({
        email: user.email,
        password: password,
      })
        .then((resp) => {
          if (resp.success) {
            setUser(resp.user);
            localStorage.setItem("@App:user", JSON.stringify(resp.user));
            setisChangePassword(false);
            setLoading(false);
            message.success("Sua senha foi alterada com sucesso.");
          } else {
            setLoading(false);
            message.error("Sua senha não pode ser alterada.");
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error("Sua senha não pode ser alterada.");
        });
    } else {
      setLoading(false);
      message.error("Sua senha não corresponde as regras de segurança.");
    }
  };

  return (
    <ConfigProvider
      theme={themeConfig}
      direction={direction}
      locale={currentAppLocale.antd}
    >
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <Routes />
          <Modal
            title="Alterar sua senha"
            centered
            open={isChangePassword}
            footer={null}
            confirmLoading={loading}
            closable={false}
          >
            <div className="my-2">
              <div className="text-center">
                <h3 className="mt-3 font-weight-bold">Altere sua senha</h3>
              </div>
              <PasswordChecker
                handleChangePassword={handleChangePassword}
                loading={loading}
              />
            </div>
          </Modal>
          <Humor
            isOpenPopupHumor={isOpenPopupHumor && !isChangePassword}
            isModal={true}
          />
          <PopupModal />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);
